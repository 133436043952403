.contacts {
    padding: 80px 0;
    background-image: url(../images/contacts.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include media("<=810px") {
        padding-bottom: 50px;
    }
}

.contacts__subtitle {
    padding: 30px 0 65px;
}

.contacts__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    text-align: center;
    @include media("<=810px") {
        flex-direction: column;
        row-gap: 30px;
        width: 100%;
    }
}

.contacts__content {
    max-width: 793px;
    flex: 0 1 100%;
    width: 100%;
}

.form {
   max-width: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
border-radius: 4px;
    border: 1px solid #FFF;
    padding: 24px;
@include media("<=810px") {
  
}
}

.form__input {
    width: 100%;
    input {
    
        width: 100%;
        font-size: 16px;
        font-weight: 700;
    color: #fff;
        border: none;
        padding: 16px 18px;
        background: rgba(255, 255, 255, 0.10);
            backdrop-filter: blur(8px);
    @include media("<=430px") {
      
    }
    }
   textarea {
    font-family: "Montserrat", sans-serif;
        width: 100%;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.60);
        border-radius: 60px;
        border: 1px solid rgba(245, 245, 245, 0.40);
        padding: 20px;
        background: transparent;
        resize: none;
   }
}

.form__checkbox {
    color: #fff;
    width: 100%;
    display: flex;
    align-items: flex-start;
    column-gap: 10px;
    font-size: 13px;
    padding: 22px 0;
    a {
        color: #0DD3EE;
            font-size: 14px;
            text-decoration: none;
    }
}

.form__button {
    margin-top: 10px;
    font-family: "Red Rose", serif;
    border-radius: 4px;
        background: #260707;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        padding: 16px;
        width: 100%;
        border: none;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s linear;
        &:hover {
          
        }
                @include media("<=810px") {
                    max-width: 100%;
                }
}

.form__link {
    color: #000;
    font-size: 13px;
    text-decoration: none;
}

.contacts__text {
    
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
}

.contacts__image {
    max-width: 387px;
    width: 100%;
    img {
        width: 100%;
        display: block;
    }
}