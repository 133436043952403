.hero {
  position: relative;
  padding: 200px 0 230px;
  background-image: url(../images/hero-bcg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @include media("<=1080px") {

  }

  @include media("<=810px") {
  
  }

  @include media("<=430px") {
    
    
  }
}

.hero__inner {
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  column-gap: 20px;
  text-align: center;
  line-height: 1.2;
  @include media("<=1280px") {

    margin: 0 auto;
  }

  @include media("<=1080px") {}

  @include media("<=810px") {
    position: relative;
    flex-direction: column;
 
   row-gap: 30px;
  }

  @include media("<=430px") {}
}



.hero__title {
font-family: "Jost", sans-serif;
  font-size: 40px;
text-transform: uppercase;
  font-weight: 500;
  position: relative;
  z-index: 3;

  margin-bottom: 40px;
  line-height: 1.2;
  @include media("<=1280px") {
   
    
  }

  @include media("<=1080px") {
    

  }

  @include media("<=810px") {
   
    
  }
    @include media("<=550px") {
     
    margin-bottom: 0;
    }

  @include media("<=430px") {
    font-size: 32px;

  }


  @include media("<=350px") {
   
  }
}

.hero__subtitle {
  font-size: 18px;
  font-weight: 600;
display: flex;
flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  @include media("<=430px") {
    font-size: 16px;
  }
}

.hero__text {

  font-size: 14px;

  @include media("<=1280px") {
    
  }
  @include media("<=430px") {
   
  }
}

.button {
  border-radius: 4px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    text-align: center;
    color: #000;
    padding: 24px;
    width: 100%;
    max-width: 390px;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s linear;
  
     border: none;

  @include media("<=810px") {
  
  }
 
  &:hover {
    background: #241f62;
    color: #FFF0F0;
  }
  
  span {
    width: 7px;
    height: 12px;
    background-image: url(../images/arrow-button.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
    &-big {
      margin-top: 24px;
      border: 2px solid #fff;
      color: #fff;
      max-width: fit-content;
  
      span {
        width: 7px;
        height: 12px;
        background-image: url(../images/arrow-w.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
}



.hero__image {
  flex: 0 1 100%;
  width: 100%;
  position: relative;
  max-width: 200px;
  margin-bottom: 10px;
    overflow: hidden;
  @include media("<=810px") {
   
  }

  @include media("<=430px") {
    
  }

  img {
    width: 100%;
    display: block;
    @include media("<=1280px") {
      
    }
  }
}

.arrow {
  position: absolute;
  right: 0;
  bottom: 0;
}
