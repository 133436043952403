.features {
  padding: 60px 0;
  background-color: #FFF;
  color: #000;
@include media("<=810px") {
  
}
}

.features__inner {

    display: flex;
    padding: 40px 0;
    gap: 20px;
    justify-content: space-between;
    @include media("<=810px") {
        flex-wrap: wrap;
        justify-content: space-around;
    }
        @include media("<=430px") {
            flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 40px;
        }
}

.features__subtitle {
    padding-top: 20px;
    font-size: 15px;
}

.features__item {
border-radius: 4px;
    border: 1px solid #000;
    padding: 16px;
    display: flex;
    flex-direction: column;
    line-height: 1.3;
    flex: 0 1 24%;
    gap: 24px;
    font-size: 18px;

    @include media("<=1080px") {
      
    }
    
        @include media("<=810px") {
            flex: 0 1 47%;
            max-width: 450px;
        }
        @include media("<=430px") {
            font-size: 16px;
            flex: 0 1 100%;
            width: 100%;
        }
        span {
            font-weight: 700;
            font-family: "Jost", sans-serif;
        }
        
}

.features__title{
    padding-top: 40px;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    @include media("<=600px") {
        font-size: 24px;
    }
}

.features__list {
    list-style: disc;
    padding-left: 15px;
   padding-top: 40px;
   padding-bottom: 30px;
   font-size: 18px;
}

.features__text {
    font-size: 18px;
}

.features__image {
    max-width: 309px;
    width: 100%;
    img {
        width: 100%;
    }
}

.text__item {
    padding: 24px;
    flex: 0 1 22%;
    border-left: 1px solid #FFF;
    line-height: 1.3;
}