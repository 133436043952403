.footer {
  padding: 30px 0;
background: #404040;
  position: relative;
  @include media("<=810px") {
    overflow: visible;
    
  }
  @include media("<=430px") {
    
  }
  
}

.footer__inner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 22px;
  @include media("<=650px") {
    flex-direction: column;
    gap: 30px;
  }
}

.footer__row {
  width: 100%;
  align-items: center;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  @include media("<=550px") {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  &:first-of-type {
    border-bottom: 1px #fff solid;
  }
}

.footer__nav {
  gap: 20px;

  display: flex;
  justify-content: flex-end;
  @include media("<=810px") {
    flex-direction: column;
  }
  @include media("<=550px") {
    justify-content: center;
  }
}

.footer__link {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 12px;
}

.copyright {

  color: #fff;
  font-size: 12px;
  font-weight: 400;
  @include media("<=810px") {
    font-size: 12px;
  }
}

.footer__cocial {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}