.header {
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px 0;
background: rgba(255, 255, 255, 0.40);
  @include media("<=810px") {

  }
  @include media("<=430px") {
  
  }
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  position: relative;
  @include media("<=550px") {
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    
  }
    
}

.header__nav {
  gap: 8px;

  display: flex;
  align-items: center;
  justify-content:flex-end;
  @include media("<=810px") {
    
    order: 3;
  }
    @include media("<=550px") {
      justify-content: space-between;
      column-gap: 10px;
    }
}

.header__link {
  padding: 8px 12px;
  border-radius: 16px;
    background: #fff;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
color: #000;
  transition: all 0.3s linear;
  @include media("<=550px") {
    order: 2;
    
    padding: 6px;
  }
  &:hover {
    opacity: 1;

  }
}

.header__link::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.08em;
  background: #fff;
  bottom: -6px;
  left: 0;
  border-radius: 3px;
  transform: scale(0, 1);
  transition: transform 0.3s ease;
}

.header__link:hover::after {
  transform: scale(1, 1);
}

.header__logo {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 24px;
font-family: "Jost", sans-serif;
  font-weight: 700;
  @include media("<=810px") {
  
    width: fit-content;
  }
    @include media("<=550px") {
      order: 1;
    }
}
