.info {
    padding:  60px 0;
    background-color: #FFF;
    color: #000;
    @include media("<=810px") {
  
    }
   
}

.info__inner {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    padding-top: 40px;
    @include media("<=810px") {
       flex-direction: column;
       align-items: center;
       justify-content: center;
       
    }
}


.info__item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 0 1 30%;
 line-height: 1.3;
   
        @include media("<=810px") {
            flex: 0 1 100%;
            width: 100%;
            justify-content: center;
            height: auto;
            padding: 40px 20px;
        }
        span {
            padding-bottom: 40px;
                font-weight: 700;
                font-size: 24px;
        }
}

.info__content {
    max-width: 552px;
    width: 100%;
}

.info__title {
font-family: "Jost", sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.info__subtitle {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 24px;
  padding-top: 16px;
}

.info__list {
    display: flex;
    flex-direction: column;

    list-style: disc;
    font-size: 16px;
    line-height: 1.2;
    padding: 24px 0 0 20px;
    @include media("<=810px") {
        
    }
    span {
        text-transform: uppercase;
        font-size: 30px;
        display: block;
    }
}


.info__text {

        font-size: 16px;
        font-weight: 400;
   line-height: 1.3;
   display: flex;
   flex-direction: column;
   align-items: center;
 
            @include media("<=1080px") {
                row-gap: 14px;
                font-size: 14px;
            }
        @include media("<=810px") {
          font-size: 16px;
        }
            
        

}

.info__link {
    display: flex;
        align-items: center;
        gap: 15px;
   margin-top: 34px;
font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    span {
            width: 7px;
            height: 12px;
            background-image: url(../images/arrow-w.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
        &:hover {
            text-decoration: underline;
        }
}