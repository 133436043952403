.about {
  overflow-x: clip;
  position: relative;
  padding: 60px 0;
  background-image: url(../images/hero-bcg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  @include media("<=1024px") {
  }
  @include media("<=810px") {
    padding-bottom: 80px;
  }
  @include media("<=430px") {
    
  }
  
}

.about__inner {
  width: 100%;
  position: relative;
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
 
  gap: 40px;
  
  @include media("<=810px") {
     flex-direction: column;
     align-items: center;
     row-gap: 30px;
  }
  @include media("<=500px") {
   
  }
}

.about__item {
  background: rgba(255, 255, 255, 0.20);
    backdrop-filter: blur(8px);
    padding: 16px;
    display: flex;
    gap: 16px;
      flex-direction: column;
      font-size: 18px;
      line-height: 1.2;
}

.about__content {
 
}

.about__text {

  font-size: 18px;
 
    font-weight: 500;
    line-height: 1.3;
    display: flex;
      flex-direction: column;

    @include media("<=430px") {
      font-size: 14px;
    }
}












